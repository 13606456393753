import { POST, GET, PATCH, DELETE } from 'utils/api';

const apiAuthenticate = (data: { email: string; password: string }) => {
  return POST('api/organization/signin', data);
};

const apiApplicationFormData = (uuid: string) => {
  return GET(`api/policy/mc/${uuid}/application/form`);
};

const apiCreateApplicationLog = (
  uuid: string,
  data: any,
  remarks: any,
  files: any,
  pdf: boolean,
) => {
  return POST(`api/policy/mc/${uuid}/application/log`, {
    data,
    remarks,
    files,
    pdf,
  });
};

const apiValidations = (uuid: string) => {
  return GET(`api/policy/mc/${uuid}`);
};

const apiCreateApplicationFile = (uuid: string, data: any) => {
  return POST(`api/policy/mc/${uuid}/application/file`, data);
};

const apiUploadFile = (uuid: string | null, data: any) => {
  if (uuid) {
    return POST(`api/policy/upload/${uuid}`, data);
  }

  return POST('api/policy/upload', data);
};

const apiPolicyList = (page: number) => {
  return GET(`api/policy/mc/list${page > 0 ? `?page=${page}` : ''}`);
};

const apiPolicyOverview = (uuid: string) => {
  return GET(`api/policy/mc/${uuid}/overview`);
};

const apiCreateNewUser = (data: any) => {
  return POST('api/organization/users', data);
};

const apiUsers = (page: number) => {
  return GET(`api/organization/users${page > 0 ? `?page=${page}` : ''}`);
};

const apiUser = (id: string) => {
  return GET(`api/organization/users/${id}`);
};

const apiUpdateUser = (id: string, data: any) => {
  return PATCH(`api/organization/users/${id}`, data);
};

const apiRemoveUser = (id: string) => {
  return DELETE(`api/organization/users/${id}`);
};

export {
  apiAuthenticate,
  apiApplicationFormData,
  apiCreateApplicationLog,
  apiValidations,
  apiCreateApplicationFile,
  apiUploadFile,
  apiPolicyList,
  apiPolicyOverview,
  apiCreateNewUser,
  apiUsers,
  apiUser,
  apiUpdateUser,
  apiRemoveUser,
};
