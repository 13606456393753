import { useState } from 'react';

import { Box, UnorderedList, ListItem, Link, Text } from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons';

// @ts-ignore
import { HashLink } from 'react-router-hash-link';

const SECTIONS = [
  { name: 'Policy Information', link: 'sectionPolicyInformation' },
  {
    name: 'Insurance Company and Broker/Agent Information',
    link: 'sectionInsuranceCompanyInformation',
  },
  { name: "Applicant's Name & Primary Address", link: 'sectionApplicant' },
  { name: 'Lessor (if applicable)', link: 'sectionLessor' },
  { name: 'Policy Period', link: 'sectionPolicyPeriod' },
  { name: 'Described Automobile', link: 'sectionVehicles' },
  { name: 'Driver Information', link: 'sectionDrivers' },
  { name: 'Previous Accidents and Insurance Claims', link: 'sectionClaims' },
  { name: 'History of Convictions', link: 'sectionConvictions' },
  {
    name: 'Rating Information - AGENT/BROKER AND COMPANY USE ONLY',
    link: 'sectionRatingInformation',
  },
  {
    name: 'Insurance Coverages Applied For',
    link: 'sectionInsuranceCoverages',
  },
  { name: 'Method of Payment', link: 'sectionMethodOfPayment' },
  { name: 'Declaration of Applicant', link: 'sectionDeclarationOfApplicant' },
  { name: 'Report of Broker/Agent', link: 'sectionReportOfBrokerAgent' },
  { name: 'Remarks', link: 'sectionRemarks' },
];

const SectionMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollWithOffset = (el: any) => {
    setIsOpen(false);
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -164;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <Box
      position="fixed"
      w={{
        base: 'calc(100% - 40px)',
        xl: 'calc(100% - 340px)',
      }}
      zIndex={1}
      cursor="pointer"
      onClick={() => setIsOpen(!isOpen)}
      top="105px"
    >
      <Box
        backgroundColor="lightgrey"
        p="10px"
        pl="20px"
        pr="20px"
        borderBottomRadius="10px"
      >
        <Text fontWeight="bold" fontSize="l">
          Section contents{' '}
          <Box float="right">
            <HamburgerIcon />
          </Box>
        </Text>
        {isOpen && (
          <UnorderedList mt="10px">
            {SECTIONS.map((section, index) => (
              <ListItem key={index}>
                <HashLink
                  smooth
                  to={`#${section.link}`}
                  scroll={scrollWithOffset}
                >
                  {section.name}
                </HashLink>
              </ListItem>
            ))}
          </UnorderedList>
        )}
      </Box>
    </Box>
  );
};

export default SectionMenu;
