import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';

import Links from 'components/sidebar/components/Links';
import { HSeparator } from 'components/separator/Separator';

import { AuthContext } from 'contexts/AuthContext';

import logo from 'assets/img/others/ppgrey.png';

const SidebarContent = ({ routes }: { routes: RoutesType[] }) => {
  const { organization, user } = useContext(AuthContext);
  const textColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      alignItems="center"
      height="100%"
      pt="25px"
      borderRadius="30px"
    >
      <Flex alignItems="center" flexDirection="column">
        <Image src={logo} alt="policypilot logo" padding="20px 20px 0px 20px" />
        <HSeparator mb="10px" w="65%" />
      </Flex>
      <Stack direction="column" mb="auto" mt="30px">
        <Box ms={'unset'}>
          <Links routes={routes} />
          <HSeparator mb="20px" mt="20px" />
          <Button
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '175px', md: '200px' }}
            h="75px"
            mt="15px"
            backgroundColor="#59606A"
            color="#fefefe"
            _hover={{ color: '#59606A', backgroundColor: '#CBD5E0' }}
            onClick={() => navigate('/admin/policies/new')}
          >
            Create New Policy
          </Button>
        </Box>
      </Stack>
      <Box
        ps="20px"
        pe={{ md: '16px', '2xl': '0px' }}
        mt="60px"
        borderRadius="30px"
      ></Box>
      <Flex mt="75px" mb="56px" justifyContent="center" alignItems="center">
        <Avatar h="48px" w="48px" src={organization.logo} me={'20px'} />
        <Box display={'block'}>
          <Text color={textColor} fontSize="md" fontWeight="700">
            {organization.name}
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
            {organization.is_admin
              ? 'Organization Account'
              : `${user.first_name} ${user.last_name}`}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SidebarContent;
